<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-dark bg-clertic">
      <div class="container-fluid justify-content-center"><img src="https://andimar.vip/tripulaciones/logo.png" alt="Logo" style="max-height:50px;"><!-- Logo centrado --></div>
    </nav>
    <div class="fluid">
      <div class="jumbotron jumbotron-fluid bg-image fotoqla">
        <div class="container text-center">
          <h1 class="display-4">Descuento para Estudiantes</h1>
          <p class="lead"></p>
        </div>
        <div class="overlay"></div>
      </div>
    </div>
    <section style="margin:35px; margin-bottom: 120px;">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <h5 class="card-header text-center">Postulación</h5>
            <div class="card-body">
              <p class="card-text text-center text-muted">Si deseas acceder a una tarifa preferencial para estudiantes, debes completar el siguiente formulario.</p>
              <div class="col-sm-12">
                <iframe class="col-sm-12" src="https://andimar.vip/mttofrm/estudiantes/" allowfullscreen style="height: 800px;"></iframe>
              </div>
            </div>
          </div>
        </div>
        <!--div class="col-md-6">
          <h3 class="text-center">Titulos</h3><p>Lorea El Ipsum Buqué oe hermano de finales qliao hermano despavila el moco hechiza yuta blapo, pura falacia oe soplamoco oe si zorra a treh deo saque matagatos de corte zoronca. a treh deo zoronca de vio quieee bellako oe hermano fineza. Tapizao gila caracho de vioh choro la yuta puro gile. te tiraste washas andai con meao pasa paca coshino ql soplamoco truco la legal, teñí puro frío anda a laar picao pero pa q po oe hermano. Embaraojato buqué, lorea.
Longi jato readi de corte saque hermano fineza chantar detonao fierro zorra abrazo pa lo amigo balazo pa lo enemigo zarpao. del corte de corte odio chantar oe hermano pasa paca coshino ql deja ganarme jato anda a laar jato ñoco. A treh deo despavila el moco washas tenis mano? de vio odio washas shilamediavola zoronca. zarpao te tiraste saque a treh deo embarao pasa paca coshino ql de vio abrazo pa lo amigo balazo pa lo enemigo, oe hermano puro gile deja ganarme tolompa conotao pasa paca coshino ql te tiraste.
Zarpao pipazo pela hermano oe si puro gile tapao en joya paquepo bellako teni mano? y que saen de corte la cani. sablazo lorea me voy en cana hechiza de corte tolompa zarpao corte hechiza oe si. baque tapao enjoya calzar fierro pipazo calmao. la yuta hermano zarpao la legal picao. odio tillas cuca blapo. Pasa paca coshino ql quieee de finales los tecneka tenis mano? qliao. conotao hermano conotao readi.
Truco tenis mano? chantar conotao truco teni mano? pasa paca coshino ql los techeka tapizao ñoco odio, me voy en cana saque ñoco shekea machucao de vio cuca longi chántale el pate perro chico, puro gile conotao terrible de perkin teni mano? de finales para la mano ascurrio washas conotao. Terrible de perkin lorea chantar saque la yuta yuta cuca, ranazo deja ganarme washas calmao teñí puro frío fineza, readi cuca puro gile tenis mano? y que saen.</p>
        </div-->
    </div>
  </section>
    <footer class="clertic-footer bg-clertic text-white text-center p-3">
      <p>&copy; 2024 Andimar</p>
    </footer>
  </div>
</template>


<style>
.bg-clertic {
  background-color: #1e2580 !important
}
.jumbotron {
  color: #fff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    background-image: url('https://clertic.cl/assets/img/ANDIMAR-048.webp');
    background-size: cover;
    background-position: top; /* Alineación de la imagen desde arriba */
    background-position-y: calc(0% - 80px); /* Subir la imagen 150px */
    background-repeat: no-repeat; /* Evitar la repetición de la imagen */
    position: relative;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.jumbotron .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
}

@media (max-width: 768px) {
            .navbar-brand {
                display: flex;
                justify-content: center;
            }
            .navbar-collapse {
                display: none;
            }
            .navbar-toggler-icon {
                margin: 0;
            }
            .jumbotron {
              background-position: top; /* Alineación de la imagen desde arriba también en dispositivos pequeños */

    }
        }



.navbar-brand img {
  filter: brightness(0) invert(1);
}

.form-control {
  border-radius: 0;
}

.form-select {
  border-radius: 0;
}

.form-floating label {
  font-size: 14px;
}

footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}
</style>